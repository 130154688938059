<template lang="html">
  <div class="">
    <div  v-if="this.open_box" @click="open_box_func()"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>


    <div  v-if="this.open_box_lead" @click="open_box_lead_func(0)"   :style="{width: '100%',zIndex: 8,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'-12%', height: '100%' }">
    </div>

    <div  v-if="this.open_connect_lead" @click="open_connect_lead_func(0)"   :style="{width: '100%',zIndex: 8,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'-14%', height: '100%' }">
    </div>
    <div  v-if="this.shown_image_flag" @click="remove_flag_enlarge()"   :style="{width: '100%',zIndex: 13,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>


    <div v-if="this.shown_image_flag" @click="remove_flag_enlarge()" :style="{width: '90%', zIndex:14,position:'fixed',marginLeft:'4%',marginTop:'2%', height: '70%',background: '#FFFFFF',borderRadius: '30px' }">
      <img :src="get_image(this.company_id,this.shown_image)"  :style="{height:'85%',marginTop:'2%', width:'40%'}"  alt="image" >
    </div>

    <div  v-if="this.open_connect_lead"  :style="{width: '90%', position:'fixed',marginLeft:'4%',marginTop:'2%',zIndex:10, height: '92%',background: '#FFFFFF',borderRadius: '30px' }">
      <div class="input_box_input_class2">
        <input type="text" class="input_box_input" v-model="search_company_connect"
        v-on:keyup="get_company_search(search_company_connect)"  placeholder="Search Company"  >
      </div>
      <div  v-if="this.search_company_connect !='' "
            :style="{height:'190px',overflowY:'auto', position:'relative',border:0,borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'26px',marginTop:'0.6%', opacity:'100%',zIndex:13, backgroundColor:'white',width:'62%', marginLeft:'20%'}">
          <div v-for="(search,idx) in search_list" :key="idx" :style="{marginLeft:'1%',textAlign:'left',  width:'99%',height:'18px', fontSize:'20px', fontWeight:'bold', marginTop:'0%'}">
            <div :style="{width:'100%',height:'20px', display:'flex',marginTop:'1%'}"
                 @click="connect_lead(search.id, search.name)">
              <div :style="{marginLeft:'1%',marginTop:'0.5%'}">
                {{search.name}}
              </div>
            </div>
          </div>
      </div>
    </div>

    <div  v-if="this.open_box_lead"  :style="{overflowY:'scroll', paddingBottom:'70px',width: '90%', position:'fixed',marginLeft:'4%',marginTop:'-7%',zIndex:10, height: '99%',background: '#FFFFFF',borderRadius: '30px' }">
      <div  :style="{height:'auto', width:'100%',fontFamily: 'Inter',fontStyle: 'normal',fontWeight: 500,fontSize: '55px'}">
          <div :style="{marginLeft:'5%', marginTop:'2%'}">
            <div :style="{height:'70px', width:'100%', display:'flex' }">
              <div :style="{fontSize:'49px', height:'10px',textAlign:'center',fontWeight:'700',marginTop:'0%', marginLeft:'40%',color:'#0b7443'}">
              Kundförfrågan
              </div>
            </div>
            <div  v-for="(lead,idx) in current_lead" :key="idx" :style="{ fontSize:'25px', marginLeft:'0%'}">
              <div class="main_comp">
                <div class="label_size">
                  Namn:
                </div>
                {{lead.name}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Kontaktinfo:
                </div>
                {{lead.contact_info}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Län:
                </div>
                {{lead.lan_chosen}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Kommun:
                </div>
                {{lead.stad}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Price:
                </div>
                {{lead.price_interval}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Category:
                </div>
                {{lead.category_chosen}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Category suggestion:
                </div>
                {{lead.category_suggestion}}
              </div>
            </div>
            <div class="box_description">
              <div class="label_size">
                Beskrivning:
              </div>
              <textarea :style="{marginLeft:'2%', whiteSpace: 'preWrap', resize: 'none',width:'100%',height:'100%'}" name="name" rows="8" cols="80" v-model="description">
              </textarea>
            </div>
            <div class="boxes">
              <div class="label_size">
                Bilder:
              </div>
              <div class="box_flow_images" data-toggle="tooltip" data-placement="top" title="Klicka på för att förstora"  v-for="(image,idxx ) in image_list" :key="idxx">
                <img :src="get_image(company_id,image.image)" @click="open_enlarge_image_box(image.image)" class="show_chosen_image_first"  alt="image" >
              </div>
            </div>
        </div>
      </div>
    </div>

    <div :style="{height:'30px', width:'100%', display:'flex' }">
      <div :style="{fontSize:'22px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0F9D58'}">
      enjord customers
      </div>
    </div>

    <div  v-if="this.open_box"  :style="{width: '90%', zIndex:'5',position:'fixed',marginLeft:'4%',marginTop:'2%', height: '82%',background: '#FFFFFF',borderRadius: '30px' }">
        <button type="button" name="button" @click="save_leads()" class="send">Save changes</button>
        <div :style="{height:'40px', width:'100%', display:'flex' }">
          <div :style="{fontSize:'29px', height:'10px',textAlign:'center',fontWeight:'700',marginTop:'1%', marginLeft:'35%',color:'#0F9D58'}">
          Lead information {{chosen_company}}
          </div>
        </div>
      <div :style="{marginLeft:'5%', marginTop:'2%'}">
        <div   :style="{display:'flex', width:'96%', justifyContent: 'flexStart',border:'0.1px grey solid', width:'97%', fontWeight:'bold'}">
          <div class="lead_width_header" >
              Name
          </div>
          <div class="lead_width_header">
            Company
          </div>
          <div class="lead_width_header">
            Created
          </div>
          <div class="lead_width_header" >
              Contact info
          </div>
          <div class="lead_width_header" >
              Lead emailed
          </div>
          <div class="lead_width_header" >
              We have contacted
          </div>
          <div class="lead_width_header" >
              Mer information
          </div>
        </div>
        <div :style="{ overflowY:'scroll',height:'280px',width:'100%'}">
          <div  v-for="(lead,idz) in company_leads_list" :key="idz" :style="{display:'flex',border:'0.1px grey solid', width:'98.3%', justifyContent: 'flexStart' }">
                <div class="lead_width">
                    {{lead.name}}
                </div>
                <div class="lead_width">
                    {{lead.company}}
                </div>
                <div class="lead_width">
                    {{lead.created}}
                </div>
                <div class="lead_width">
                    {{lead.contact_info}}
                </div>
                <div class="lead_width">
                    {{lead.lead_emailed}}
                </div>
                <div class="lead_width">
                  <input type="text" :style="{width:'70px'}" name="" v-model="lead.company_contacted">
                    <!-- {{lead.company_contacted}} -->
                </div>
                <div class="lead_width" @click="open_box_lead_func(lead.id)">
                    Mer information
                </div>
                <b-icon icon="x-lg" :style="{marginTop:'1%'}" @click="delete_lead(lead.id, lead.company_id)" />
            </div>
          </div>
        </div>
      </div>

    <div class="input_box_input_class2">
      <input type="text" class="input_box_input" v-model="search_company" v-on:keyup="get_company_search(search_company)"  placeholder="Search Company"  >
    </div>
    <div  v-if="this.search_company !='' "
          :style="{height:'190px',overflowY:'auto', position:'relative',border:0,borderWidth:'0', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'26px',marginTop:'0.6%', opacity:'100%',zIndex:13, backgroundColor:'white',width:'62%', marginLeft:'20%'}">
        <div v-for="(search,idx) in search_list" :key="idx" :style="{marginLeft:'1%',textAlign:'left',  width:'99%',height:'18px', fontSize:'20px', fontWeight:'bold', marginTop:'0%'}">
          <div :style="{width:'100%',height:'20px', display:'flex',marginTop:'1%'}"
               @click="get_summary_from_a_company_func(search.id, search.name)">
            <div :style="{marginLeft:'1%',marginTop:'0.5%'}">
              {{search.name}}
            </div>
          </div>
        </div>
    </div>

    <div :style="{height:'30px', marginTop:'2%',width:'100%', display:'flex' }">
      <div :style="{fontSize:'18px', height:'10px',position:'absolute',fontWeight:'600',marginTop:'0%', marginLeft:'2%',color:'#0F9D58'}">
      Senaste eller valt lead/Kundförfrågan
      </div>
    </div>

    <div :style="{height:'auto', marginLeft:'3%', marginTop:'0%'}"  >
      <div   :style="{display:'flex', width:'98%', justifyContent: 'flexStart',border:'0.1px grey solid', width:'97%', fontWeight:'bold'}">
        <div class="lead_width_header" >
            Lead company
        </div>
        <div class="lead_width_header">
            Nr leads total
        </div>
        <div class="lead_width_header" >
            Nr leads this month
        </div>
        <div class="lead_width_header">
            Nr leads last month
        </div>
        <div class="lead_width_header">
            Nr page clicks total
        </div>
        <div class="lead_width_header">
            Verified
        </div>

        <!-- <div class="lead_width_header" >
            Nr page clicks this month
        </div>
        <div class="lead_width_header">
            Nr page clicks last month
        </div> -->
        <div class="lead_width_header">
          More information
        </div>
      </div>
      <div  v-for="(lead,idxx) in leads_list"  :key="idxx" :style="{display:'flex',border:'0.1px grey solid', width:'97%', justifyContent: 'flexStart' }">
        <div class="lead_width">
            {{lead.company}}
        </div>
        <div class="lead_width" >
          {{lead.total_leads}}
        </div>
        <div class="lead_width" >
          {{lead.leads_this_month}}
        </div>
        <div class="lead_width" >
          {{lead.leads_last_month}}
        </div>
        <div class="lead_width" >
          {{lead.total_nr_clicks}}
        </div>
        <div class="lead_width" >
          {{lead.verified}}
        </div>

        <!-- <div class="lead_width" >
          {{lead.nr_clicks_last_month}}
        </div>
        <div class="lead_width" >
          {{lead.nr_clicks_this_month }}
        </div> -->
        <div class="lead_width" @click="open_box_func(lead.company, lead.company_id)">
            <span :style="{textDecoration:'underline', color:'blue', cursor:'pointer'}">More information</span>
        </div>
      </div>
    </div>

    <!-- <div  v-if="this.open_box_lead"  :style="{width: '90%', position:'fixed',marginLeft:'4%',marginTop:'2%',zIndex:10, height: '92%',background: '#FFFFFF',borderRadius: '30px' }">
      <div  :style="{height:'auto', width:'100%',fontFamily: 'Inter',fontStyle: 'normal',fontWeight: 500,fontSize: '55px'}">
          <div :style="{marginLeft:'5%', marginTop:'2%'}">
            <div :style="{height:'70px', width:'100%', display:'flex' }">
              <div :style="{fontSize:'49px', height:'10px',textAlign:'center',fontWeight:'700',marginTop:'0%', marginLeft:'40%',color:'#0b7443'}">
              Kundförfrågan
              </div>
            </div>
            <div  v-for="(lead,idx) in current_lead" :key="idx" :style="{ fontSize:'25px', marginLeft:'0%'}">
              <div class="main_comp">
                <div class="label_size">
                  Namn:
                </div>
                {{lead.name}}
              </div>
              <div class="main_comp">
                <div class="label_size">
                  Kontaktinfo:
                </div>
                {{lead.contact_info}}
              </div>
            </div>
            <div class="box_description">
              <div class="label_size">
                Beskrivning:
              </div>
              <textarea :style="{marginLeft:'2%', whiteSpace: 'preWrap', resize: 'none',width:'100%',height:'100%'}" name="name" rows="8" cols="80" v-model="description">
              </textarea>
            </div>
            <div class="boxes">
              <div class="label_size">
                Bilder:
              </div>
              <div class="box_flow_images" data-toggle="tooltip" data-placement="top" title="Klicka på för att förstora"  v-for="(image,idxx ) in image_list" :key="idxx">
                <img :src="get_image(company_id,image.image)" @click="open_enlarge_image_box(image.image)" class="show_chosen_image_first"  alt="image" >
              </div>
            </div>
        </div>
      </div>
    </div> -->


    <div   :style="{width: '90%',marginLeft:'4%',marginTop:'2%', height: '82%',background: '#FFFFFF',borderRadius: '30px' }">
      <!-- <button type="button" name="button" @click="save_general_leads()" class="send">Save changes</button> -->
      <div :style="{height:'40px', width:'100%', display:'flex' }">
        <div :style="{fontSize:'29px', height:'10px',textAlign:'left',fontWeight:'700',marginTop:'1%', color:'#0F9D58'}">
        Generalla leads
        </div>
      </div>
      <div :style="{marginLeft:'0%', marginTop:'2%'}">
        <div   :style="{display:'flex', width:'96%', justifyContent: 'flexStart',border:'0.1px grey solid', width:'97%', fontWeight:'bold'}">
          <div class="lead_width_header" >
              Name
          </div>
          <div class="lead_width_header">
            Created
          </div>
          <div class="lead_width_header" >
              Contact info
          </div>
          <div class="lead_width_header" >
              Lead contacted
          </div>
          <div class="lead_width_header" >
            Mer information
          </div>
          <div class="lead_width_header" >
              Save lead
          </div>
          <div class="lead_width_header" >
              Koppla lead
          </div>
          <div class="lead_width_header" >
              Delete lead
         </div>
        </div>
        <div :style="{ height:'280px',width:'100%'}">
          <div  v-for="(lead,idz) in general_leads_list" :key="idz" :style="{display:'flex',border:'0.1px grey solid', width:'98.3%', justifyContent: 'flexStart' }">
                <div class="lead_width">
                    {{lead.name}}
                </div>
                <div class="lead_width">
                    {{lead.created}}
                </div>
                <div class="lead_width">
                    {{lead.contact_info}}
                </div>
                <div class="lead_width">
                  <input type="text" :style="{width:'70px'}" name="" v-model="lead.lead_contacted">
                </div>
                <div class="lead_width" @click="open_box_lead_func(lead.id)">
                    Mer information
                </div>
                <div class="lead_width" >
                  <button type="button" name="button" @click="save_general_lead(lead.id,lead.lead_contacted)">
                  Save lead
                </button>
                </div>
                <div class="lead_width" >
                  <button type="button" name="button" @click="open_connect_lead_func(lead.id)">
                  Koppla till reparatör
                  </button>
                </div>

                <div class="lead_width" @click="delete_general_lead(lead.id)">
                  <b-icon icon="x-lg" :style="{marginTop:'1%'}"  />
                </div>
            </div>
          </div>
        </div>
      </div>

      <div :style="{height:'100px'}">

      </div>

  </div>
</template>

<script>

// import router from '../router/index'
import axios from 'axios';
import {API_URL} from '../../config.js'
export default {
  data:function(){
    return{
      publicPath: process.env.BASE_URL +'',
      leads_list:[],
      open_box:false,
      search_company_connect:'',
      search_company:'',
      chosen_company:'',
      search_list:[],
      company_leads_list:[],
      open_box_lead:false,
      current_lead:[],
      description:"",
      image_list:[],
      company_id:0,
      open_connect_lead:false,
      connected_lead_id:0,
      general_leads_list:[],
      shown_image_flag:false,
      shown_image:''
    }
  },
  mounted(){
    //this.get_company_search("")
    this.get_companies_with_leads_this_month_func()
    this.get_general_leads()
  },
  methods:{
    remove_flag_enlarge(){
      this.shown_image_flag = false
    },
    open_enlarge_image_box(image){
      this.shown_image_flag = true
      this.shown_image=image
    },
    open_connect_lead_func(lead_id){
      this.connected_lead_id = lead_id
      this.get_lead(lead_id)
      if(this.open_connect_lead){
        this.open_connect_lead = false
      }
      else{
        this.open_connect_lead = true
      }
    },
    open_box_lead_func(lead_id){
      if(this.open_box_lead){
        this.open_box_lead = false
      }else{
        this.open_box_lead = true
        this.get_lead(lead_id)
      }
    },
    get_lead(lead_id){
      var self = this
      axios({
         method:'get',
         url: API_URL+ '/get_lead'+'?lead_id='+lead_id,
       })
       .then(function(response){
         self.current_lead = response.data["show_lead_list"]
         self.description = response.data["show_lead_list"][0]["description"]
         self.image_list=response.data["image_list"]
       })
    },
    get_image(id,image){
      if (image){
        var data = 'enjordrepair_user' + id + "/" + image
        return this.$func.get_image(data)
      }
    },
    get_general_leads(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_general_leads',
        })
        .then(function(response){
          self.general_leads_list = response.data
        })
    },
    connect_lead(company_id){
      var self = this
      axios({
          method:'post',
          url: API_URL+ '/connect_lead',
          data:{lead_id:this.connected_lead_id,company_id:company_id}
        })
        .then(function(){
          self.$alert("lead connected")
       })
    },
    save_general_lead(lead_id, general_lead_contacted){
      var self = this
      axios({
          method:'put',
          url: API_URL+ '/save_general_lead',
          data:{lead_id:lead_id, general_lead_contacted:general_lead_contacted}
        })
        .then(function(){
          self.$alert("lead saved")
       })
    },
    save_leads(){
      var self = this
      axios({
          method:'put',
          url: API_URL+ '/save_leads',
          data:{leads:this.company_leads_list}
        })
        .then(function(){
            self.$alert("leads saved")
        })
    },
    get_companies_with_leads_this_month_func(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_companies_with_leads_this_month',
        })
        .then(function(response){

          for (var i = 0; i < response.data.length; i++) {
            self.get_summary_from_a_company_func(response.data[i].company_id,response.data[i].company_name)
          }
        })
    },
    get_company_search(search_item){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_companies'+'?search_item='+  search_item,
        })
        .then(function(response){
            self.search_list=response.data

                  // self.search_opened = true
        })
    },
    delete_general_lead(lead_id){
      this.$confirm("vill du verkligen radera?","",'error').then(() => {
        var self =this
        axios({
           method:'delete',
           url: API_URL+ '/delete_general_lead',
           data:{  lead_id:lead_id}
         })
         .then(function(){
             self.get_general_leads()
         })
       })
    },
    delete_lead(lead_id, company_id){
       this.$confirm("vill du verkligen radera?","",'error').then(() => {

         var self = this
         axios({
            method:'delete',
            url: API_URL+ '/delete_lead',
            data:{  lead_id:lead_id}
          })
          .then(function(){
              self.get_a_companys_leads_func(company_id)
          })
       })
   },
    get_summary_from_a_company_func(company_id,company_name){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_summary_from_a_company'+'?company_id='+company_id + '&company_name='+company_name,
        })
        .then(function(response){
            self.leads_list.push(response.data[0])
            self.search_company=''
        })

    },
    get_a_companys_leads_func(company_id){
      this.company_id = company_id

      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_a_companys_leads'+'?company_id='+company_id ,
        })
        .then(function(response){
            self.company_leads_list=response.data

        })
    },
    open_box_func(company,company_id){
      if(this.open_box){
        this.open_box = false
      }else{
        this.open_box = true
        this.chosen_company=company
        this.get_a_companys_leads_func(company_id)
      }
    }
  },

}
</script>

<style lang="css" scoped>
input:focus {outline:none!important;}
.lead_width_header{
  width: 190px;
  height: 50px;
  /* border: 0.1px Solid grey; */
}

.lead_width{
  width: 190px;
  height: 70px;
  font-size: 13px;
  padding-top: 0.5%;
  /* text-align:left;
  padding-left: 2%; */
  /* border: 0.5px Solid; */

}

.input_box_input_class2{
  width: 70%;
  height: 59px;
  margin-left: 2%;
  border-radius: 12px;
  color: #0F9D58;
  margin-top: 2%;
  border: 4px solid #59419B6F ;
}

.input_box_input{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #0F9D58;
  border-color: #0F9D58;
  color: black;
  padding-left: 0%;
  border-style: none;
}
.search{
  width: 100%;
  height: 87px;
  margin-top: 2%;
  background: white;
  /* rgba(196, 196, 196, 0.32); */
  border-radius: 50px;
  display: flex;
}
.input_box_input_class2{
  width: 90%;
  height: 59px;
  margin-left: 4%;
  border-radius: 12px;
  color: #0F9D58;
  margin-top: 2%;
  border: 4px solid #59419B6F ;
}

.input_box_input{
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #0F9D58;
  border-color: #0F9D58;
  color: black;
  padding-left: 2%;
  border-style: none;
}
.send{
  color:white;
  padding-top: 1%;
   margin-left: 77%;
   margin-top: 2%;
   padding-bottom: 0.5%;
   font-size: 16px;
   width:90px;
   height:35px;
    border-style:None;
     border-radius: 18px;
     background-color: #0F9D58;
}
.main_comp{
  display: flex;
  width: auto;
    margin-left: 0%;
    margin-top: 1%;
}
.lead_width{
  width: 300px;
  height: 90px;
  font-size: 18px;
  padding-top: 0.5%;
   text-align:center;

}

.box_description{
  height:210px;
  padding-bottom: 0.2%;
  display:flex;
  margin-top: 1%;
  width: 80%;
  font-size: 25px;
}

.label_size{
 width:150px;
 font-weight: 600
}



.boxes{
  /* height:30px;
  margin-left: 5%;
   display:flex;
   overflow-x: auto;
   scrollbar-width:thin; */
  font-size: 25px;
  text-align: left;
   height:20px;
   padding-bottom: 0.2%;
   margin-top: 2%;
   /* margin-left: 2%; */
display: flex;
    margin-top: 1%;

    width: 90%;

}
.box_flow_images{
  margin-left: 1%;
  color: grey;
  height: 90px;
  width: 98px;
  /* overflow-x: auto; */
/* width: auto; */
display: flex;
}


.show_chosen_image_first{
 height: 100%;
    border-radius: 8px;
  width: 100%;
  object-fit: contain;
  background-color: white;
cursor: pointer;
}
</style>
